import { useAppDispatch } from "Hooks/Store";
import NavBarLanding from "Pages/HomeSlash/NavBarLanding";
import { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { ContactAction } from "Redux/App/Actions/Auth";
import { ContactWrapper } from "./styles";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useAppDispatch();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!name || !email || !message) return;
    const data = {
      name,
      email,
      message,
    };
    dispatch<any>(ContactAction(data));
    setName("");
    setEmail("");
    setMessage("");
  };
  return (
    <ContactWrapper>
      <NavBarLanding bg="rgb(57,57,57)" />
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <h2 className="text-center mt-5 mb-4 pt-5 fw-bold">Contact Us</h2>
            <h5 className="text-center">
              Get in touch to see how we can help or for general enquiries
              around our contractor database
            </h5>
            <p className="text-center">
              We will be happy to meet at your offices, or over a coffee if you
              prefer
            </p>
            <Card className="border-0 mt-4">
              <Card.Body>
                <Form className="mt-4 mb-4" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    className="btn-submit"
                    variant="primary"
                    type="submit"
                  >
                    Send
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ContactWrapper>
  );
};

export default ContactPage;
