import Login from "Pages/Auth/Login";
import Layout from "Components/Layout/Main/index";
import Theme from "Styles/theme";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Home from "./Home";
import PersonalInformation from "./Home/PersonalInformation";
import SignUp from "Pages/Auth/SignUp";
import Otp from "Pages/Auth/Otp";
import Expertise from "./Home/Expertise";
import SoftSkills from "./Home/SoftSkills";
import Profile from "./Profile";
import ProfileSettings from "./Profile Settings";
import { useSelector } from "react-redux";
import { IsRecordEntered, requestCompleted } from "Redux/App";
import { useAppDispatch } from "Hooks/Store";
import { HomeSlash } from "./HomeSlash";
import { AppRoutes } from "../constants/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactPage from "./Contact";
import TermsConditions from "./TermsConditions";
import ForgotPassword from "./Auth/Forgot/ForgotPassword";
import ResetPassword from "./Auth/Forgot/ResetPassword";
import UpdatedPassword from "./Auth/Forgot/UpdatedPassword";
import ResetLinkSuccess from "./Auth/Forgot/ResetLinkSuccess";
import ScrollToTop from "./ScrollTop";

function App() {
  const dispatch = useAppDispatch();
  window.onbeforeunload = function () {
    dispatch<any>(requestCompleted());
  };

  const isRecordExist = useSelector(IsRecordEntered);

  const layoutWrapper = (component: JSX.Element) => (
    <PrivateRoute>
      <Layout>{component}</Layout>
    </PrivateRoute>
  );
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path={AppRoutes.homeSlash}
            element={
              // <LoginRedirect>
              <HomeSlash />
              // </LoginRedirect>
            }
          />
          <Route
            path={AppRoutes.login}
            element={
              <LoginRedirect>
                <Login />
              </LoginRedirect>
            }
          />
          {isRecordExist ? (
            <Route
              path={AppRoutes.portal}
              element={layoutWrapper(<Profile />)}
            />
          ) : (
            <Route path={AppRoutes.portal} element={layoutWrapper(<Home />)} />
          )}
          {/* <Route
            path="/profile"
            element={layoutWrapper(<Profile />)}
          /> */}
          <Route
            path={AppRoutes.profileSetting}
            element={layoutWrapper(<ProfileSettings />)}
          />
          <Route
            path={AppRoutes.personalInformation}
            element={layoutWrapper(<PersonalInformation />)}
          />
          <Route
            path={AppRoutes.softSkill}
            element={layoutWrapper(<SoftSkills />)}
          />
          <Route
            path={AppRoutes.expertise}
            element={layoutWrapper(<Expertise />)}
          />
          <Route path={AppRoutes.signUp} element={<SignUp />} />
          <Route path={AppRoutes.forgot} element={<ForgotPassword />} />
          <Route path={AppRoutes.reset} element={<ResetPassword />} />
          <Route path={AppRoutes.updated} element={<UpdatedPassword />} />
          <Route path={AppRoutes.linkSuccess} element={<ResetLinkSuccess />} />
          <Route path={AppRoutes.otp} element={<Otp />} />
          <Route path={AppRoutes.contact} element={<ContactPage />} />
          <Route
            path={AppRoutes.termsConditions}
            element={<TermsConditions />}
          />

          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const PrivateRoute = (props: { children: JSX.Element }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? props.children : <Navigate to={AppRoutes.login} />;
};

const LoginRedirect = (props: { children: JSX.Element }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? <Navigate to={AppRoutes.portal} /> : props.children;
};

export default App;
