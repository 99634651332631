import { Button, Form, Input, Select } from "antd";
import { useAppDispatch } from "Hooks/Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExpertiseAction } from "Redux/App/Actions/Items";
import { loading as stateLoading, user } from "Redux/App";
import { useEffect, useState } from "react";
import {
  WellComeBoxActaurial,
  DashBoardWrapper,
  MainHeading,
  InputsHeading,
  ExpertiseOuterWrapper,
} from "../styles";

const Home = () => {
  const loading = useSelector(stateLoading);
  const userData = useSelector(user);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [isExpertiseOpen, setIsExpertiseOpen] = useState<boolean>(false);
  const [specifyOtherField, setSpecifyOtherField] = useState<boolean>(false);
  const [otherField, setOtherField] = useState<string>("");

  useEffect(() => {
    if (
      [
        "areaOfExpertise",
        "areaOfSpeciality",
        "qualification",
        "pqe",
        "totalYears",
      ].every((key) => key in userData)
    ) {
      form.setFieldsValue({
        areaOfExpertise: userData["areaOfExpertise"],
        areaOfSpeciality: userData["areaOfSpeciality"],
        qualification: userData["qualification"],
        pqe: userData["pqe"],
        totalYears: userData["totalYears"],
      });
      if (userData["areaOfExpertise"] === "other") {
        setSpecifyOtherField(true);
        setOtherField(userData["areaOfExpertise"]);
      }
    }
  }, []);

  const onFinish = (values: any) => {
    values["userID"] = userData?.userID;
    console.log("Success:", values);
    if (specifyOtherField) {
      values["areaOfExpertise"] =
        values["areaOfExpertise"] === "other"
          ? otherField
          : values["areaOfExpertise"];
      // values["areaOfExpertise"] = [...values["areaOfExpertise"], otherField];
    }
    values["pqe"] = +values["pqe"];
    values["totalYears"] = +values["totalYears"];
    dispatch<any>(ExpertiseAction(values, navigate));
  };
  const onChangeExpertise = (value: any) => {
    // setIsExpertiseOpen(false);
    if (value.includes("other")) {
      setSpecifyOtherField(true);
    } else {
      // setIsExpertiseOpen(true);
      setSpecifyOtherField(false);
    }
  };
  return (
    <ExpertiseOuterWrapper>
      <DashBoardWrapper>
        <WellComeBoxActaurial>
          <div>
            <MainHeading>Expertise</MainHeading>

            <Form
              form={form}
              layout="vertical"
              className="FormWrapper"
              onFinish={onFinish}
            >
              <InputsHeading>Area of Expertise</InputsHeading>
              <Form.Item name="areaOfExpertise">
                <Select
                  placeholder="e.g. General Insurance "
                  options={[
                    { label: "Recruitment", value: "Recruitment" },
                    { label: "Employer", value: "Employer" },
                    { label: "General Insurance", value: "General Insurance" },
                    { label: "Life Insurance", value: "Life Insurance" },
                    { label: "Pensions", value: "Pensions" },
                    { label: "Banking", value: "Banking" },
                    { label: "Healthcare", value: "Healthcare" },
                    { label: "Investment", value: "Investment" },
                    { label: "Protection", value: "Protection" },
                    { label: "Other - Please Specify", value: "other" },
                  ]}
                  showSearch
                  // open={isExpertiseOpen}
                  onChange={onChangeExpertise}
                // onFocus={() => setIsExpertiseOpen(true)}
                // onBlur={() => setIsExpertiseOpen(false)}
                // onSearch={() => setIsExpertiseOpen(false)}
                // mode="multiple"
                // maxTagCount={3}
                // maxTagTextLength={10}
                ></Select>
              </Form.Item>
              {specifyOtherField && (
                <Form.Item name="otherField" style={{ marginTop: 10 }}>
                  <Input
                    placeholder="Please Specify"
                    value={otherField}
                    onChange={(e) => setOtherField(e.target.value)}
                  />
                </Form.Item>
              )}
              <InputsHeading>Specific Areas of Speciality</InputsHeading>

              <Form.Item name="areaOfSpeciality">
                <Select
                  placeholder="e.g. Pricing "
                  options={[
                    { label: "Pricing", value: "Pricing" },
                    { label: "Underwriting", value: "Underwriting" },
                    { label: "Reserving", value: "Reserving" },
                    { label: "IFRS17", value: "IFRS17" },
                    { label: "Capital Modelling", value: "Capital Modelling" },
                    {
                      label: "Coding/Programming",
                      value: "Coding/Programming",
                    },
                    { label: "Management", value: "Management" },
                    { label: "Cat Modelling", value: "Cat Modelling" },
                    { label: "GLMs", value: "GLMs" },
                    { label: "Machine Learning", value: "Machine Learning" },
                    {
                      label: "Personal Lines - Motor",
                      value: "Personal Lines - Motor",
                    },
                    {
                      label: "Personal Lines - Household",
                      value: "Personal Lines - Household",
                    },
                    {
                      label: "Personal Lines - Other",
                      value: "Personal Lines - Other",
                    },
                    {
                      label: "Commercial Lines - Property",
                      value: "Commercial Lines - Property",
                    },
                    {
                      label: "Commercial Lines - Casualty",
                      value: "Commercial Lines - Casualty",
                    },
                    {
                      label: "Commercial Lines - Speciality",
                      value: "Commercial Lines - Speciality",
                    },
                    { label: "Reinsurance", value: "Reinsurance" },
                    { label: "Lloyds", value: "Lloyds" },
                    { label: "London Market", value: "London Market" },
                    { label: "Change Management", value: "Change Management" },
                    { label: "Model Change", value: "Model Change" },
                    { label: "Risk", value: "Risk" },
                    {
                      label: "Financial Reporting",
                      value: "Financial Reporting",
                    },
                    { label: "M&A", value: "M&A" },
                    { label: "ALM", value: "ALM" },
                    { label: "ERM", value: "ERM" },
                    { label: "Product Design", value: "Product Design" },
                    { label: "Valuation", value: "Valuation" },
                    { label: "Transfer Pricing", value: "Transfer Pricing" },
                    { label: "Run-Off", value: "Run-Off" },
                    { label: "ESG", value: "ESG" },
                  ]}
                  // open={isExpertiseOpen}
                  // onChange={onChangeExpertise}
                  // onFocus={() => setIsExpertiseOpen(true)}
                  // onBlur={() => setIsExpertiseOpen(false)}
                  // onSearch={() => setIsExpertiseOpen(false)}
                  mode="multiple"
                  // maxTagCount={3}
                  maxTagTextLength={10}
                ></Select>
              </Form.Item>
              <InputsHeading>Qualification</InputsHeading>
              <Form.Item name="qualification">
                <Select
                  options={[
                    { label: "FIA", value: "FIA" },
                    { label: "FFA", value: "FFA" },
                    { label: "CAA", value: "CAA" },
                    { label: "Not Qualified", value: "Not Qualified" },
                    { label: "Part Qualified", value: "Part Qualified" },
                    { label: "CERA", value: "CERA" },
                    { label: "FCAS", value: "FCAS" },
                    { label: "MAAA", value: "MAAA" },
                    {
                      label: "Qualified (Non-IFOA)",
                      value: "Qualified (Non-IFOA)",
                    },
                  ]}
                  placeholder="Enter your Qualification"
                  showSearch
                ></Select>
              </Form.Item>
              <InputsHeading>Years PQE?</InputsHeading>
              <Form.Item name="pqe">
                <Input type="number" min={0} />
              </Form.Item>
              <InputsHeading>Total Years</InputsHeading>
              <Form.Item name="totalYears">
                <Input type="number" min={0} />
              </Form.Item>
              <Form.Item>
                <div className="main-btn">
                  <Button
                    className="lets-go"
                    htmlType="submit"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </WellComeBoxActaurial>
      </DashBoardWrapper>
    </ExpertiseOuterWrapper>
  );
};

export default Home;
