import styled from "styled-components";

export const LoginWrapper = styled.div`
  height: 100vh;
  background: #f2f8ff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #d91e49 !important;
  }
`;
export const LoginCard = styled.div`
  flex-basis: 700px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  padding-top: 36px;
  @media (max-width: 768px) {
    padding: 100px 20px;
  }
`;
export const LoginHeading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 50px;
`;
export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 3rem;
  .FormWrapper {
    width: 100% !important;
  }
  .ant-form {
    width: 50%;
  }
  .ant-input {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 359px;
    height: 48px;
  }
  .register_link {
    padding: 20px 0px;
  }

  .ant-btn {
    border-radius: 2px;
    margin-top: 20px;
    width: 128px;
    border: none;
    height: 43px;
    color: black;
  }
  [ant-click-animating-without-extra-node="true"]:after {
    content: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 33px;
  }
  .ant-input-affix-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 359px;
    height: 48px;
  }
  .ant-form-item-explain-error {
    text-align: left;
    margin-left: 150px;
  }
  .form-submit-btn {
    background: #393939;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 359px;
    height: 48px;
    color: #fff;
    margin-bottom: 10px;
  }
  .overlay {
    height: 100px;
    background-color: white;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    opacity: 0.5;
    z-index: 1;
  }
  @media (max-width: 768px) {
    .ant-form {
      width: 100%;
    }
  }
`;
