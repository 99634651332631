import styled from "styled-components";

export const WellComeBoxActaurial = styled.div`
  max-width: 75%;
  margin: auto;
  padding-top: 80px;
  .main-btn {
    display: flex;
    justify-content: center;
  }
  .lets-go-div {
    text-align: center;
  }
  .reset-btn {
    border: 1px solid #393939;
    border-radius: 4px;
    margin-left: 13px !important;
    width: 150px;
    height: 48px;
    color: #393939;
    // margin-top: 35px;
  }
  .reset-btn:hover {
    background: #393939;
    border-radius: 4px;
    margin-left: 13px !important;
    width: 150px;
    height: 48px;
    color: #ffffff !important;
    border: 1px solid #393939;
  }

  .reset-btn:focus,
  .reset-btn:active {
    color: #393939;
    border: 1px solid #393939;
  }
  .lets-go {
    background: #393939;
    border-radius: 4px;
    margin-left: 13px !important;
    width: 150px;
    height: 48px;
    color: #ffffff;
    // margin-top: 35px;
  }
  .lets-go:hover,
  .lets-go:active,
  .lets-go:focus {
    border: none;
  }
  .ant-input,
  .ant-select {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 359px;
    // min-height: 48px;
  }
  .ant-input-number-input,
  .ant-input-number-in-form-item {
    background: #ffffff;
    // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 359px;
    // min-height: 48px;
  }
  .ant-input-number:hover,
  .ant-input-number:active,
  .ant-input-number:focus-visible,
  .ant-input-number:focus {
    border: 1px solid #d9d9d9 !important;

  }
  .ant-input-number:focus, .ant-input-number-focused {
    border-color: #d9d9d9; !important;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 0%); !important;
    border-right-width: 1px;
    outline: 0;
  }
  // .ant-input-number-in-form-item:hover,
  .ant-input-number-in-form-item:active,
  .ant-input-number-in-form-item:focus-visible,
  .ant-input-number-in-form-item:focus {
    border: 1px solid #d9d9d9 !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-table {
    background: none;
  }

  .profile-items {
    // flex: 1 0 33%;
    margin: 10px;
  }

  .ant-select {
    width: 310px !important;
  }
  .ant-input, .ant-input-number-input, .ant-input-number-in-form-item {
    width: 310px !important;
    height: 46px;
  }
  #totalYears {
    display: flex;
  }
  #totalYears div .ant-input {
    width: 150px !important;
    margin-right: 10px !important;
  }
  #totalYears div .ant-input-number-input, #totalYears div .ant-input-number-in-form-item {
    width: 150px !important;
    margin-right: 10px !important;
  }
  #areaOfExpertise,
  #baseLocation,
  #qualification,
  #softwares {
    padding-top: 15px !important;
  }
  .search-table .ant-table-row {
    cursor: pointer;
  }

  ul{
    padding-inline-start: 20px !important;
  }
  .ant-table-tbody .ant-table-row .ant-table-cell ul{
    height: 85px;
    overflow: scroll;
    display:grid;
    align-items: center;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 16px 10px 16px !important;
}
`;
export const DashBoardWrapper = styled.div`
  background: #f2f2f2;
`;

export const HintHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  margin-left: 4px;
  line-height: 29px;
  color: #393939;
  text-align: start;
`;

export const MainHeading = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #393939;
  text-align: center;
`;

export const UserHeading = styled.p`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 0px;
  margin-top: 5px;
  /* identical to box height, or 29px */

  /* Primary Black */

  color: #393939;
`;

export const UpdateMyProfileButton = styled.button`
  width: 172px;
  height: 48px;
  margin-top: 10px;

  /* bg-color */

  background: #f2f2f2;
  border-radius: 4px;
  border: none !important;
  .btn-inner:hover {
    background-color: transparent !important;
  }
`;

export const ContractorHeading = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  margin-top: 36px;
  margin-bottom: 16px;
  color: #393939;
`;
