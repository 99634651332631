import {
  Button,
  Select,
  Input,
  Table,
  Image,
  Form,
  Row,
  Col,
  Alert,
  Radio,
  Dropdown,
  Menu,
  Tag,
  MenuProps,
  Rate,
  InputNumber,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import {
  WellComeBoxActaurial,
  DashBoardWrapper,
  UpdateMyProfileButton,
  ContractorHeading,
  HintHeading,
} from "./styles";
import { UserHeading } from "./styles";
import { useSelector } from "react-redux";
import {
  data,
  user,
  loading as stateLoading,
  resetLoading as resetLoadingState,
  requestCompleted,
  setIsReload,
  isReload,
} from "Redux/App";
import { useAppDispatch } from "Hooks/Store";
import {
  GetItemsAction,
  ResetItemsAction,
  SearchItemsAction,
} from "Redux/App/Actions/Items";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputsHeading } from "Pages/Home/styles";
import MyComponent from "Pages/Profile Settings/test";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { AppRoutes } from "constants/routes";
import moment from "moment";

const Home = () => {
  const navigate = useNavigate();
  window.onbeforeunload = function () {
    dispatch<any>(setIsReload(true));
    // dispatch<any>(requestCompleted());
  };

  useEffect(() => {
    if (isReloadFlag) {
      dispatch<any>(setIsReload(false));
      dispatch<any>(requestCompleted());

    }

    dispatch<any>(GetItemsAction(0, navigate));
  }, []);

  const [minYears, setMinYears] = useState<number>(0);
  const [maxYears, setMaxYears] = useState<number>(0);
  const userData = useSelector(user);
  const profilesData = useSelector(data);
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const loading = useSelector(stateLoading);
  const resetLoading = useSelector(resetLoadingState);
  const isReloadFlag = useSelector(isReload);

  const [locations, setLocations] = useState<
    {
      label: string;
      value: string;
    }[]
  >([
    {
      label: "United Kingdom",
      value: "United Kingdom",
    },
    {
      label: "Afghanistan",
      value: "Afghanistan",
    },
    {
      label: "Albania",
      value: "Albania",
    },
    {
      label: "Algeria",
      value: "Algeria",
    },
    {
      label: "American Samoa",
      value: "American Samoa",
    },
    {
      label: "Andorra",
      value: "Andorra",
    },
    {
      label: "Angola",
      value: "Angola",
    },
    {
      label: "Anguilla",
      value: "Anguilla",
    },
    {
      label: "Antarctica",
      value: "Antarctica",
    },
    {
      label: "Antigua and Barbuda",
      value: "Antigua and Barbuda",
    },
    {
      label: "Argentina",
      value: "Argentina",
    },
    {
      label: "Armenia",
      value: "Armenia",
    },
    {
      label: "Aruba",
      value: "Aruba",
    },
    {
      label: "Australia",
      value: "Australia",
    },
    {
      label: "Austria",
      value: "Austria",
    },
    {
      label: "Azerbaijan",
      value: "Azerbaijan",
    },
    {
      label: "Bahamas",
      value: "Bahamas",
    },
    {
      label: "Bahrain",
      value: "Bahrain",
    },
    {
      label: "Bangladesh",
      value: "Bangladesh",
    },
    {
      label: "Barbados",
      value: "Barbados",
    },
    {
      label: "Belarus",
      value: "Belarus",
    },
    {
      label: "Belgium",
      value: "Belgium",
    },
    {
      label: "Belize",
      value: "Belize",
    },
    {
      label: "Benin",
      value: "Benin",
    },
    {
      label: "Bermuda",
      value: "Bermuda",
    },
    {
      label: "Bhutan",
      value: "Bhutan",
    },
    {
      label: "Bolivia",
      value: "Bolivia",
    },
    {
      label: "Bosnia and Herzegovina",
      value: "Bosnia and Herzegovina",
    },
    {
      label: "Botswana",
      value: "Botswana",
    },
    {
      label: "Bouvet Island",
      value: "Bouvet Island",
    },
    {
      label: "Brazil",
      value: "Brazil",
    },
    {
      label: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
    },
    {
      label: "British Virgin Islands",
      value: "British Virgin Islands",
    },
    {
      label: "Brunei",
      value: "Brunei",
    },
    {
      label: "Bulgaria",
      value: "Bulgaria",
    },
    {
      label: "Burkina Faso",
      value: "Burkina Faso",
    },
    {
      label: "Burundi",
      value: "Burundi",
    },
    {
      label: "Cambodia",
      value: "Cambodia",
    },
    {
      label: "Cameroon",
      value: "Cameroon",
    },
    {
      label: "Canada",
      value: "Canada",
    },
    {
      label: "Cape Verde",
      value: "Cape Verde",
    },
    {
      label: "Caribbean Netherlands",
      value: "Caribbean Netherlands",
    },
    {
      label: "Cayman Islands",
      value: "Cayman Islands",
    },
    {
      label: "Central African Republic",
      value: "Central African Republic",
    },
    {
      label: "Chad",
      value: "Chad",
    },
    {
      label: "Chile",
      value: "Chile",
    },
    {
      label: "China",
      value: "China",
    },
    {
      label: "Christmas Island",
      value: "Christmas Island",
    },
    {
      label: "Cocos (Keeling) Islands",
      value: "Cocos (Keeling) Islands",
    },
    {
      label: "Colombia",
      value: "Colombia",
    },
    {
      label: "Comoros",
      value: "Comoros",
    },
    {
      label: "Cook Islands",
      value: "Cook Islands",
    },
    {
      label: "Costa Rica",
      value: "Costa Rica",
    },
    {
      label: "Croatia",
      value: "Croatia",
    },
    {
      label: "Cuba",
      value: "Cuba",
    },
    {
      label: "Curaçao",
      value: "Curaçao",
    },
    {
      label: "Cyprus",
      value: "Cyprus",
    },
    {
      label: "Czechia",
      value: "Czechia",
    },
    {
      label: "DR Congo",
      value: "DR Congo",
    },
    {
      label: "Denmark",
      value: "Denmark",
    },
    {
      label: "Djibouti",
      value: "Djibouti",
    },
    {
      label: "Dominica",
      value: "Dominica",
    },
    {
      label: "Dominican Republic",
      value: "Dominican Republic",
    },
    {
      label: "Ecuador",
      value: "Ecuador",
    },
    {
      label: "Egypt",
      value: "Egypt",
    },
    {
      label: "El Salvador",
      value: "El Salvador",
    },
    {
      label: "Equatorial Guinea",
      value: "Equatorial Guinea",
    },
    {
      label: "Eritrea",
      value: "Eritrea",
    },
    {
      label: "Estonia",
      value: "Estonia",
    },
    {
      label: "Eswatini",
      value: "Eswatini",
    },
    {
      label: "Ethiopia",
      value: "Ethiopia",
    },
    {
      label: "Falkland Islands",
      value: "Falkland Islands",
    },
    {
      label: "Faroe Islands",
      value: "Faroe Islands",
    },
    {
      label: "Fiji",
      value: "Fiji",
    },
    {
      label: "Finland",
      value: "Finland",
    },
    {
      label: "France",
      value: "France",
    },
    {
      label: "French Guiana",
      value: "French Guiana",
    },
    {
      label: "French Polynesia",
      value: "French Polynesia",
    },
    {
      label: "French Southern and Antarctic Lands",
      value: "French Southern and Antarctic Lands",
    },
    {
      label: "Gabon",
      value: "Gabon",
    },
    {
      label: "Gambia",
      value: "Gambia",
    },
    {
      label: "Georgia",
      value: "Georgia",
    },
    {
      label: "Germany",
      value: "Germany",
    },
    {
      label: "Ghana",
      value: "Ghana",
    },
    {
      label: "Gibraltar",
      value: "Gibraltar",
    },
    {
      label: "Greece",
      value: "Greece",
    },
    {
      label: "Greenland",
      value: "Greenland",
    },
    {
      label: "Grenada",
      value: "Grenada",
    },
    {
      label: "Guadeloupe",
      value: "Guadeloupe",
    },
    {
      label: "Guam",
      value: "Guam",
    },
    {
      label: "Guatemala",
      value: "Guatemala",
    },
    {
      label: "Guernsey",
      value: "Guernsey",
    },
    {
      label: "Guinea",
      value: "Guinea",
    },
    {
      label: "Guinea-Bissau",
      value: "Guinea-Bissau",
    },
    {
      label: "Guyana",
      value: "Guyana",
    },
    {
      label: "Haiti",
      value: "Haiti",
    },
    {
      label: "Heard Island and McDonald Islands",
      value: "Heard Island and McDonald Islands",
    },
    {
      label: "Honduras",
      value: "Honduras",
    },
    {
      label: "Hong Kong",
      value: "Hong Kong",
    },
    {
      label: "Hungary",
      value: "Hungary",
    },
    {
      label: "Iceland",
      value: "Iceland",
    },
    {
      label: "India",
      value: "India",
    },
    {
      label: "Indonesia",
      value: "Indonesia",
    },
    {
      label: "Iran",
      value: "Iran",
    },
    {
      label: "Iraq",
      value: "Iraq",
    },
    {
      label: "Ireland",
      value: "Ireland",
    },
    {
      label: "Isle of Man",
      value: "Isle of Man",
    },
    {
      label: "Israel",
      value: "Israel",
    },
    {
      label: "Italy",
      value: "Italy",
    },
    {
      label: "Ivory Coast",
      value: "Ivory Coast",
    },
    {
      label: "Jamaica",
      value: "Jamaica",
    },
    {
      label: "Japan",
      value: "Japan",
    },
    {
      label: "Jersey",
      value: "Jersey",
    },
    {
      label: "Jordan",
      value: "Jordan",
    },
    {
      label: "Kazakhstan",
      value: "Kazakhstan",
    },
    {
      label: "Kenya",
      value: "Kenya",
    },
    {
      label: "Kiribati",
      value: "Kiribati",
    },
    {
      label: "Kosovo",
      value: "Kosovo",
    },
    {
      label: "Kuwait",
      value: "Kuwait",
    },
    {
      label: "Kyrgyzstan",
      value: "Kyrgyzstan",
    },
    {
      label: "Laos",
      value: "Laos",
    },
    {
      label: "Latvia",
      value: "Latvia",
    },
    {
      label: "Lebanon",
      value: "Lebanon",
    },
    {
      label: "Lesotho",
      value: "Lesotho",
    },
    {
      label: "Liberia",
      value: "Liberia",
    },
    {
      label: "Libya",
      value: "Libya",
    },
    {
      label: "Liechtenstein",
      value: "Liechtenstein",
    },
    {
      label: "Lithuania",
      value: "Lithuania",
    },
    {
      label: "Luxembourg",
      value: "Luxembourg",
    },
    {
      label: "Macau",
      value: "Macau",
    },
    {
      label: "Madagascar",
      value: "Madagascar",
    },
    {
      label: "Malawi",
      value: "Malawi",
    },
    {
      label: "Malaysia",
      value: "Malaysia",
    },
    {
      label: "Maldives",
      value: "Maldives",
    },
    {
      label: "Mali",
      value: "Mali",
    },
    {
      label: "Malta",
      value: "Malta",
    },
    {
      label: "Marshall Islands",
      value: "Marshall Islands",
    },
    {
      label: "Martinique",
      value: "Martinique",
    },
    {
      label: "Mauritania",
      value: "Mauritania",
    },
    {
      label: "Mauritius",
      value: "Mauritius",
    },
    {
      label: "Mayotte",
      value: "Mayotte",
    },
    {
      label: "Mexico",
      value: "Mexico",
    },
    {
      label: "Micronesia",
      value: "Micronesia",
    },
    {
      label: "Moldova",
      value: "Moldova",
    },
    {
      label: "Monaco",
      value: "Monaco",
    },
    {
      label: "Mongolia",
      value: "Mongolia",
    },
    {
      label: "Montenegro",
      value: "Montenegro",
    },
    {
      label: "Montserrat",
      value: "Montserrat",
    },
    {
      label: "Morocco",
      value: "Morocco",
    },
    {
      label: "Mozambique",
      value: "Mozambique",
    },
    {
      label: "Myanmar",
      value: "Myanmar",
    },
    {
      label: "Namibia",
      value: "Namibia",
    },
    {
      label: "Nauru",
      value: "Nauru",
    },
    {
      label: "Nepal",
      value: "Nepal",
    },
    {
      label: "Netherlands",
      value: "Netherlands",
    },
    {
      label: "New Caledonia",
      value: "New Caledonia",
    },
    {
      label: "New Zealand",
      value: "New Zealand",
    },
    {
      label: "Nicaragua",
      value: "Nicaragua",
    },
    {
      label: "Niger",
      value: "Niger",
    },
    {
      label: "Nigeria",
      value: "Nigeria",
    },
    {
      label: "Niue",
      value: "Niue",
    },
    {
      label: "Norfolk Island",
      value: "Norfolk Island",
    },
    {
      label: "North Korea",
      value: "North Korea",
    },
    {
      label: "North Macedonia",
      value: "North Macedonia",
    },
    {
      label: "Northern Mariana Islands",
      value: "Northern Mariana Islands",
    },
    {
      label: "Norway",
      value: "Norway",
    },
    {
      label: "Oman",
      value: "Oman",
    },
    {
      label: "Pakistan",
      value: "Pakistan",
    },
    {
      label: "Palau",
      value: "Palau",
    },
    {
      label: "Palestine",
      value: "Palestine",
    },
    {
      label: "Panama",
      value: "Panama",
    },
    {
      label: "Papua New Guinea",
      value: "Papua New Guinea",
    },
    {
      label: "Paraguay",
      value: "Paraguay",
    },
    {
      label: "Peru",
      value: "Peru",
    },
    {
      label: "Philippines",
      value: "Philippines",
    },
    {
      label: "Pitcairn Islands",
      value: "Pitcairn Islands",
    },
    {
      label: "Poland",
      value: "Poland",
    },
    {
      label: "Portugal",
      value: "Portugal",
    },
    {
      label: "Puerto Rico",
      value: "Puerto Rico",
    },
    {
      label: "Qatar",
      value: "Qatar",
    },
    {
      label: "Republic of the Congo",
      value: "Republic of the Congo",
    },
    {
      label: "Romania",
      value: "Romania",
    },
    {
      label: "Russia",
      value: "Russia",
    },
    {
      label: "Rwanda",
      value: "Rwanda",
    },
    {
      label: "Réunion",
      value: "Réunion",
    },
    {
      label: "Saint Barthélemy",
      value: "Saint Barthélemy",
    },
    {
      label: "Saint Helena, Ascension and Tristan da Cunha",
      value: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
      label: "Saint Kitts and Nevis",
      value: "Saint Kitts and Nevis",
    },
    {
      label: "Saint Lucia",
      value: "Saint Lucia",
    },
    {
      label: "Saint Martin",
      value: "Saint Martin",
    },
    {
      label: "Saint Pierre and Miquelon",
      value: "Saint Pierre and Miquelon",
    },
    {
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    {
      label: "Samoa",
      value: "Samoa",
    },
    {
      label: "San Marino",
      value: "San Marino",
    },
    {
      label: "Saudi Arabia",
      value: "Saudi Arabia",
    },
    {
      label: "Senegal",
      value: "Senegal",
    },
    {
      label: "Serbia",
      value: "Serbia",
    },
    {
      label: "Seychelles",
      value: "Seychelles",
    },
    {
      label: "Sierra Leone",
      value: "Sierra Leone",
    },
    {
      label: "Singapore",
      value: "Singapore",
    },
    {
      label: "Sint Maarten",
      value: "Sint Maarten",
    },
    {
      label: "Slovakia",
      value: "Slovakia",
    },
    {
      label: "Slovenia",
      value: "Slovenia",
    },
    {
      label: "Solomon Islands",
      value: "Solomon Islands",
    },
    {
      label: "Somalia",
      value: "Somalia",
    },
    {
      label: "South Africa",
      value: "South Africa",
    },
    {
      label: "South Georgia",
      value: "South Georgia",
    },
    {
      label: "South Korea",
      value: "South Korea",
    },
    {
      label: "South Sudan",
      value: "South Sudan",
    },
    {
      label: "Spain",
      value: "Spain",
    },
    {
      label: "Sri Lanka",
      value: "Sri Lanka",
    },
    {
      label: "Sudan",
      value: "Sudan",
    },
    {
      label: "Suriname",
      value: "Suriname",
    },
    {
      label: "Svalbard and Jan Mayen",
      value: "Svalbard and Jan Mayen",
    },
    {
      label: "Sweden",
      value: "Sweden",
    },
    {
      label: "Switzerland",
      value: "Switzerland",
    },
    {
      label: "Syria",
      value: "Syria",
    },
    {
      label: "São Tomé and Príncipe",
      value: "São Tomé and Príncipe",
    },
    {
      label: "Taiwan",
      value: "Taiwan",
    },
    {
      label: "Tajikistan",
      value: "Tajikistan",
    },
    {
      label: "Tanzania",
      value: "Tanzania",
    },
    {
      label: "Thailand",
      value: "Thailand",
    },
    {
      label: "Timor-Leste",
      value: "Timor-Leste",
    },
    {
      label: "Togo",
      value: "Togo",
    },
    {
      label: "Tokelau",
      value: "Tokelau",
    },
    {
      label: "Tonga",
      value: "Tonga",
    },
    {
      label: "Trinidad and Tobago",
      value: "Trinidad and Tobago",
    },
    {
      label: "Tunisia",
      value: "Tunisia",
    },
    {
      label: "Turkey",
      value: "Turkey",
    },
    {
      label: "Turkmenistan",
      value: "Turkmenistan",
    },
    {
      label: "Turks and Caicos Islands",
      value: "Turks and Caicos Islands",
    },
    {
      label: "Tuvalu",
      value: "Tuvalu",
    },
    {
      label: "Uganda",
      value: "Uganda",
    },
    {
      label: "Ukraine",
      value: "Ukraine",
    },
    {
      label: "United Arab Emirates",
      value: "United Arab Emirates",
    },
    {
      label: "United States",
      value: "United States",
    },
    {
      label: "United States Minor Outlying Islands",
      value: "United States Minor Outlying Islands",
    },
    {
      label: "United States Virgin Islands",
      value: "United States Virgin Islands",
    },
    {
      label: "Uruguay",
      value: "Uruguay",
    },
    {
      label: "Uzbekistan",
      value: "Uzbekistan",
    },
    {
      label: "Vanuatu",
      value: "Vanuatu",
    },
    {
      label: "Vatican City",
      value: "Vatican City",
    },
    {
      label: "Venezuela",
      value: "Venezuela",
    },
    {
      label: "Vietnam",
      value: "Vietnam",
    },
    {
      label: "Wallis and Futuna",
      value: "Wallis and Futuna",
    },
    {
      label: "Western Sahara",
      value: "Western Sahara",
    },
    {
      label: "Yemen",
      value: "Yemen",
    },
    {
      label: "Zambia",
      value: "Zambia",
    },
    {
      label: "Zimbabwe",
      value: "Zimbabwe",
    },
    {
      label: "Åland Islands",
      value: "Åland Islands",
    },
  ]);
  const [softwares, setSoftwares] = useState([
    {
      label: "Excel",
      value: "Excel",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Word",
      value: "Word",
      isSelected: false,
      grading: 0,
    },
    {
      label: "PowerPoint",
      value: "PowerPoint",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Power BI",
      value: "Power BI",
      isSelected: false,
      grading: 0,
    },
    {
      label: "C",
      value: "C",
      isSelected: false,
      grading: 0,
    },
    {
      label: "ResQ",
      value: "ResQ",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Psicle",
      value: "Psicle",
      isSelected: false,
      grading: 0,
    },
    {
      label: "InsurSight",
      value: "InsurSight",
      isSelected: false,
      grading: 0,
    },
    {
      label: "R",
      value: "R",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Python",
      value: "Python",
      isSelected: false,
      grading: 0,
    },
    {
      label: "VB",
      value: "VB",
      isSelected: false,
      grading: 0,
    },
    {
      label: "VBA",
      value: "VBA",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Emblem",
      value: "Emblem",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Radar",
      value: "Radar",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Tyche",
      value: "Tyche",
      isSelected: false,
      grading: 0,
    },
    {
      label: "RMS",
      value: "RMS",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Prophet",
      value: "Prophet",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Moses",
      value: "Moses",
      isSelected: false,
      grading: 0,
    },
  ]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: string, record: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <Avatar size={50} icon={<UserOutlined />} />
            <div>{record.name}</div>
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "activityRole",
      key: "activityRole",
      width: 200,
      render: (text: string, record: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <div>{record.activityRole}</div>
          </div>
        );
      },
    },
    {
      title: "Locality",
      dataIndex: "baseLocation",
      key: "baseLocation",
      width: 50,
      render: (text: string, record: any) => {
        return (
          <p>
            {record?.baseLocation?.map((item: any) => {
              if (
                item === record?.baseLocation[record?.baseLocation.length - 1]
              ) {
                return item;
              } else {
                return item + ", ";
              }
            })}
          </p>
        );
      },
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
      key: "qualification",
      width: 50,
    },
    {
      title: "Experience (Years)",
      dataIndex: "totalYears",
      key: "totalYears",
      width: 50,
    },
    {
      title: "IR35 Preference",
      dataIndex: "ir35",
      key: "ir35",
      width: 50,
    },
    {
      title: "Areas of Expertise",
      dataIndex: "areaOfExpertise",
      key: "areaOfExpertise",
      width: 50,
    },
    {
      title: "Areas of Specialty",
      dataIndex: "areaOfSpeciality",
      key: "areaOfSpeciality",
      width: 50,
      render: (text: string, record: any) => {
        return (
          <ul>
            {record?.areaOfSpeciality?.map((item: any) => {
              return <li>{item}</li>;
            })}
          </ul>
        );
      },
    },
    // {
    //   title: "Availability",
    //   dataIndex: "availability",
    //   key: "availability",
    //   width: 50,
    //   render: (text: string, record: any) => {
    //     let availability = "";
    //     if (record?.availability === "Available now") {
    //       availability = "Available now";
    //     } else {
    //       if (record?.availability) {
    //         availability = record?.availability.split("T")[0];
    //       }
    //     }
    //     return <div>{availability}</div>;
    //   },
    // },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      width: 50,
      render: (text: string, record: any) => {
        let availability = "";
        if (record?.availability === "Available now") {
          availability = "Available now";
        } else if (record?.availability) {
          // Assuming record.availability is in the format "YYYY-MM-DDTHH:mm:ss"
          const recordDate = new Date(record.availability);
          const currentDate = new Date();

          if (recordDate <= currentDate) {
            availability = "Available now";
          } else {
            const day = String(recordDate.getDate()).padStart(2, "0");
            const month = String(recordDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
            const year = recordDate.getFullYear();
            availability = `${day}-${month}-${year}`;
          }
        }
        return <div>{availability}</div>;
      },
    },
    // {
    //   title: "Last Updated Date",
    //   dataIndex: "updatedAt",
    //   key: "lastUpdatedDate",
    //   width: 50,
    // },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "lastUpdatedDate",
      width: 50,
      render: (text: string, record: any) => {
        if (record?.updatedAt) {
          const lastUpdated = moment(record.updatedAt, "DD-MM-YYYY");
          const now = moment();
          const duration = moment.duration(now.diff(lastUpdated));

          const yearsAgo = duration.years();
          const monthsAgo = duration.months();
          const weeksAgo = duration.weeks();
          const daysAgo = duration.days();

          if (yearsAgo > 0) {
            return `${yearsAgo} year${yearsAgo === 1 ? "" : "s"} ago`;
          } else if (monthsAgo > 0) {
            return `${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
          } else if (weeksAgo > 0) {
            return `${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
          } else if (daysAgo > 0) {
            return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
          } else {
            return "Today";
          }
        }

        return "";
      },
    },
  ];

  const onFinish = (values: any) => {
    if (!values["ir35"]) {
      delete values["ir35"];
    }
    if (minYears && maxYears) {
      values["totalYears"] = {
        min: +minYears,
        max: +maxYears,
      };
    } else if (minYears) {
      values["totalYears"] = {
        min: +minYears,
        max: 100,
      };
    } else if (maxYears) {
      values["totalYears"] = {
        min: 0,
        max: +maxYears,
      };
    }
    values["softwares"] = softwares.filter((item) => {
      if (item.isSelected) {
        const newSoftware = {
          label: item.label,
          value: item.label,
          isSelected: item.isSelected,
          grading: +item.grading,
        };
        return newSoftware;
      }
    });

    if (values["pqe"]) {
      values["pqe"] = values["pqe"].toString();
    }

    console.log("Success:", values);
    dispatch<any>(SearchItemsAction(values));
  };

  const onChangeMinYears = (val: any) => {
    setMinYears(val);
  };
  const onChangeMaxYears = (val: any) => {
    setMaxYears(val);
  };

  const onReset = () => {
    form.resetFields();
    setMinYears(0);
    setMaxYears(0);
    const resetSoftwares = softwares.map((item) => ({
      ...item,
      isSelected: false,
      grading: 0,
    }));
    console.table(resetSoftwares);
    setSoftwares(resetSoftwares);
    setSelectedTags([]);
    dispatch<any>(ResetItemsAction({}));
  };

  const items: MenuProps["items"] = softwares.map((software: any) => {
    return {
      key: software.value,
      label: software.label,
      icon: (
        <Checkbox checked={software.isSelected} style={{ marginRight: 5 }} />
      ),
      children: [
        {
          key: "0",
          label: (
            <div
              style={{
                width: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <span>0</span>
            </div>
          ),
        },
        {
          key: "1",
          label: (
            <div
              style={{
                width: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <span>1</span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              style={{
                width: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <span>2</span>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              style={{
                width: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <span>3</span>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              style={{
                width: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <span>4</span>
            </div>
          ),
        },
      ],
    };
  });

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    // const flat = selectedTags.flat();
    let isAlreadyExist = selectedTags.findIndex(
      (item) => item[1] === e.keyPath[1]
    );
    // selectedTags.forEach((item, index)=>{
    //   console.clear()
    //   console.log(flat)

    //   console.table(item)
    //   console.log("&&&&&&&&&&&&&&&&")
    //   console.table(e.keyPath)
    //   if(e.keyPath[1]===item[1]){
    //     // isAlreadyExist= true
    //     return index
    //   }
    // })
    console.log(isAlreadyExist);
    if (isAlreadyExist > -1) {
      console.log("Inside if");
      selectedTags[isAlreadyExist] = e.keyPath;
      setSelectedTags([...selectedTags]);
    } else {
      setSelectedTags((prevTags) => {
        return [...prevTags, e.keyPath];
      });
    }
    setSoftwares(
      softwares.map((software: any) => {
        if (software.value === e.keyPath[1]) {
          return { ...software, grading: e.keyPath[0], isSelected: true };
        }
        return software;
      })
    );
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  return (
    <DashBoardWrapper>
      <WellComeBoxActaurial>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            background: "#fff",
            padding: 20,
          }}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <Avatar size={64} icon={<UserOutlined />} />
            <div>
              <UserHeading>Welcome {userData?.name}!</UserHeading>
              <div>{userData?.email}</div>
            </div>
          </div>
          <div>
            <UpdateMyProfileButton>
              <Button
                type="text"
                onClick={() => navigate(AppRoutes.personalInformation)}
              >
                Update my details
              </Button>
            </UpdateMyProfileButton>
          </div>
        </div>
        <ContractorHeading>Search for contractors</ContractorHeading>
        <Form
          form={form}
          layout="vertical"
          className="FormWrapper"
          onFinish={onFinish}
          style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
        >
          <Form.Item name={"areaOfExpertise"} className="profile-items">
            <Select
              placeholder="Area of Expertise"
              options={[
                { label: "Recruitment", value: "Recruitment" },
                { label: "Employer", value: "Employer" },
                { label: "General Insurance", value: "General Insurance" },
                { label: "Life Insurance", value: "Life Insurance" },
                { label: "Pensions", value: "Pensions" },
                { label: "Banking", value: "Banking" },
                { label: "Healthcare", value: "Healthcare" },
                { label: "Investment", value: "Investment" },
                { label: "Protection", value: "Protection" },
              ]}
              showSearch
              allowClear
            // mode="multiple"
            ></Select>
          </Form.Item>

          <Form.Item name={"areaOfSpeciality"} className="profile-items">
            <Select
              placeholder="Specific Areas of Speciality"
              options={[
                { label: "Pricing", value: "Pricing" },
                { label: "Underwriting", value: "Underwriting" },
                { label: "Reserving", value: "Reserving" },
                { label: "IFRS17", value: "IFRS17" },
                { label: "Capital Modelling", value: "Capital Modelling" },
                {
                  label: "Coding/Programming",
                  value: "Coding/Programming",
                },
                { label: "Management", value: "Management" },
                { label: "Cat Modelling", value: "Cat Modelling" },
                { label: "GLMs", value: "GLMs" },
                { label: "Machine Learning", value: "Machine Learning" },
                {
                  label: "Personal Lines - Motor",
                  value: "Personal Lines - Motor",
                },
                {
                  label: "Personal Lines - Household",
                  value: "Personal Lines - Household",
                },
                {
                  label: "Personal Lines - Other",
                  value: "Personal Lines - Other",
                },
                {
                  label: "Commercial Lines - Property",
                  value: "Commercial Lines - Property",
                },
                {
                  label: "Commercial Lines - Casualty",
                  value: "Commercial Lines - Casualty",
                },
                {
                  label: "Commercial Lines - Speciality",
                  value: "Commercial Lines - Speciality",
                },
                { label: "Reinsurance", value: "Reinsurance" },
                { label: "Lloyds", value: "Lloyds" },
                { label: "London Market", value: "London Market" },
                { label: "Change Management", value: "Change Management" },
                { label: "Model Change", value: "Model Change" },
                { label: "Risk", value: "Risk" },
                {
                  label: "Financial Reporting",
                  value: "Financial Reporting",
                },
                { label: "M&A", value: "M&A" },
                { label: "ALM", value: "ALM" },
                { label: "ERM", value: "ERM" },
                { label: "Product Design", value: "Product Design" },
                { label: "Valuation", value: "Valuation" },
                { label: "Transfer Pricing", value: "Transfer Pricing" },
                { label: "Run-Off", value: "Run-Off" },
                { label: "ESG", value: "ESG" },
              ]}
              mode="multiple"
              style={{ flex: "1 0 33.33%" }}
            ></Select>
          </Form.Item>

          <Form.Item name={"baseLocation"} className="profile-items">
            <Select
              options={locations}
              placeholder="Location Based"
              // mode="multiple"
              showSearch
              allowClear
            ></Select>
          </Form.Item>
          <Row>
            <Col span={16}>
              <Row>
                <Form.Item name={"qualification"} className="profile-items">
                  <Select
                    // mode="multiple"
                    allowClear
                    options={[
                      { label: "No Preferences", value: "" },
                      { label: "FIA", value: "FIA" },
                      { label: "FFA", value: "FFA" },
                      { label: "CAA", value: "CAA" },
                      { label: "Not Qualified", value: "Not Qualified" },
                      { label: "Part Qualified", value: "Part Qualified" },
                      { label: "CERA", value: "CERA" },
                      { label: "FCAS", value: "FCAS" },
                      { label: "MAAA", value: "MAAA" },
                      {
                        label: "Qualified (Non-IFOA)",
                        value: "Qualified (Non-IFOA)",
                      },
                    ]}
                    placeholder="Qualified"
                    showSearch
                  ></Select>
                </Form.Item>
                <Form.Item name={"pqe"} className="profile-items">
                  {/* <Input type="number" min={0} step={undefined} placeholder="Years PQE" /> */}
                  <InputNumber min={0} placeholder="Years PQE" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item name={"softwares"}>
                  {/* <Select
                    options={softwares}
                    placeholder="Software/Programming Languages"
                    // mode="multiple"
                    showSearch
                    className="profile-items"
                  ></Select> */}
                  <Dropdown menu={menuProps} placement="bottom">
                    <div>
                      {selectedTags.length === 0 ? (
                        <div
                          style={{
                            display: "inline-block",
                            border: "1px solid #d9d9d9",
                            width: "310px",
                            minHeight: "49px",
                            padding: "10px 5px 0px 5px",
                            margin: "10px",
                            background: "white",
                          }}
                        >
                          <p
                            style={{
                              color: "#BBBBBB",
                              fontSize: "14px",
                              marginLeft: "10px",
                            }}
                          >
                            Software/Programming Languages
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "inline-block",
                            border: "1px solid #d9d9d9",
                            width: "310px",
                            minHeight: "49px",
                            padding: "8px 5px 8px 5px",
                            margin: "10px",
                            background: "white",
                          }}
                        >
                          {selectedTags?.map((tag) => (
                            <Tag
                              key={tag}
                              style={{ margin: 4 }}
                              closable
                              onClose={() => {
                                setSoftwares(
                                  softwares?.map((item) => {
                                    if (item.value === tag[1]) {
                                      item.isSelected = false;
                                    }
                                    return item;
                                  })
                                );
                                setSelectedTags(
                                  selectedTags.filter((t) => t !== tag)
                                );
                              }}
                            >
                              {tag[1]} : <strong>{tag[0]}</strong>
                            </Tag>
                          ))}
                        </div>
                      )}
                    </div>
                  </Dropdown>
                </Form.Item>
                {/* <MyComponent softwares={softwares} setSoftwares={setSoftwares}/> */}

                <Form.Item name={"ir35"}>
                  <Select
                    options={[
                      { label: "Inside Only", value: "Inside Only" },
                      { label: "Outside Only", value: "Outside Only" },
                      { label: "No Preferences", value: "" },
                    ]}
                    placeholder="IR35 Preference"
                    // mode="multiple"
                    allowClear
                    className="profile-items"
                  ></Select>
                </Form.Item>
              </Row>
            </Col>
            <Col span={8}>
              <Form.Item name={"totalYears"} className="profile-items">
                <InputsHeading>Total Years of Experience</InputsHeading>
                <div id="totalYears">
                  <div>
                    {/* <Input
                      type="number"
                      min={1}
                      max={1000}
                      step={undefined}
                      // value={minYears}
                      onChange={onChangeMinYears}
                    /> */}
                    <InputNumber
                      min={1}
                      max={1000}
                      onChange={onChangeMinYears}
                    />
                    <HintHeading>Minimum Years of Experience</HintHeading>
                  </div>
                  <div>
                    {/* <Input
                      type="number"
                      min={1}
                      max={1000}
                      step={undefined}
                      // value={maxYears}
                      onChange={onChangeMaxYears}
                    /> */}
                    <InputNumber
                      min={1}
                      max={1000}
                      onChange={onChangeMaxYears}
                    />
                    <HintHeading>Maximum Years of Experience</HintHeading>
                  </div>
                </div>
                {minYears &&
                  maxYears &&
                  parseFloat(`${minYears}`) > parseFloat(`${maxYears}`) ? (
                  <Alert
                    message="Min years should be less than max years"
                    type="error"
                  />
                ) : null}
                {minYears && maxYears && minYears === maxYears ? (
                  <Alert
                    message="Min years and max years should not be same"
                    type="error"
                  />
                ) : null}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} offset={24}>
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  className="reset-btn"
                  htmlType="reset"
                  onClick={onReset}
                  loading={resetLoading}
                  disabled={loading}
                >
                  Reset
                </Button>
                <Button
                  className="lets-go"
                  htmlType="submit"
                  loading={loading}
                  disabled={
                    minYears > 0 && maxYears > 0
                      ? minYears > maxYears || minYears === maxYears
                      : false
                  }
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <ContractorHeading>Search results</ContractorHeading>
        <Table
          className="search-table"
          dataSource={profilesData.data}
          pagination={{
            pageSize: profilesData?.data?.length
              ? profilesData?.data?.length
              : 10,
            total: profilesData.total,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          onChange={(pagination) => {
            console.log(pagination);

            dispatch<any>(GetItemsAction(pagination));
          }}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate(AppRoutes.profileSetting, { state: record });
              },
            };
          }}
        />
      </WellComeBoxActaurial>
    </DashBoardWrapper>
  );
};

export default Home;
