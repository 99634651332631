import { Button, Col, Form, Input, Row } from "antd";
import BoxActaurialAuthIcon from "Assets/Logos/BoxActaurialAuthIcon";
import { AppRoutes } from "constants/routes";
import { Link } from "react-router-dom";
import { FormWrapper, LoginCard, LoginWrapper } from "./styles";

const UpdatedPassword = () => {
  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginBottom: "22px" }}>
              <BoxActaurialAuthIcon />
            </div>
          </Col>
        </Row>

        <h1 className="form-primary-heading">Success!</h1>
        <p className="form-text ">
          Your password has been successfully changed
        </p>

        <FormWrapper>
          <Form layout="vertical" className="FormWrapper">
            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ textAlign: "center" }}>
                <Link to={AppRoutes.login}>
                  <Button
                    className="form-submit-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Login
                  </Button>
                </Link>
              </div>
            </Form.Item>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default UpdatedPassword;
