import { useEffect } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import {
  LoginCard,
  LoginWrapper,
  FormWrapper,
  BackHomeWrapper,
} from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoginAction } from "Redux/App/Actions/Auth";
import { loading as stateLoading } from "Redux/App";
import { useAppDispatch } from "Hooks/Store";
import BoxActaurialAuthIcon from "Assets/Logos/BoxActaurialAuthIcon";
import { AppRoutes } from "constants/routes";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Login = () => {
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    let authToken = sessionStorage.getItem("Auth Token");
    if (authToken) {
      navigate(AppRoutes.portal);
    }
  }, [navigate]);

  const onFinish = (values: { email: string; password: string }) => {
    dispatch<any>(LoginAction(values, navigate));
  };

  return (
    <>
      <BackHomeWrapper>
        <Row className="row-wrapper">
          <Col span={2}>
            <Link to="/" className="back-link">
              <ArrowLeftOutlined color={"#393939"} />
              Back to home
            </Link>
          </Col>
        </Row>
      </BackHomeWrapper>

      <LoginWrapper>
        <LoginCard>
          <Row>
            <Col span={24}>
              <div style={{ textAlign: "center", marginBottom: "22px" }}>
                <BoxActaurialAuthIcon />
              </div>
            </Col>
          </Row>

          <h1 className="form-primary-heading">Welcome back!</h1>
          <p className="form-text ">This won’t take long</p>
          <FormWrapper>
            <Form
              form={form}
              layout="vertical"
              className="FormWrapper"
              onFinish={onFinish}
            >
              {loading ? <div className="overlay"></div> : ""}
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input type="email" placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <div style={{ textAlign: "center" }}>
                  <Button
                    className="form-submit-btn"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Login
                  </Button>
                </div>
              </Form.Item>
              <p>
                <span style={{ color: "#BBBBBB" }}>
                  Don’t have an account?&nbsp;
                </span>
                <Link
                  to={AppRoutes.signUp}
                  style={{ color: "#393939", textDecoration: "underline" }}
                >
                  Sign Up
                </Link>
              </p>
              <p>
                <span style={{ color: "#BBBBBB" }}>
                  Forgot your password?&nbsp;
                </span>
                <Link
                  to={AppRoutes.forgot}
                  style={{ color: "#393939", textDecoration: "underline" }}
                >
                  Reset here
                </Link>
              </p>
            </Form>
          </FormWrapper>
        </LoginCard>
      </LoginWrapper>
    </>
  );
};

export default Login;
