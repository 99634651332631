import styled from "styled-components";

export const SoftSkillOuterWrapper = styled.div`
  height: 160vh;
  background: #f2f2f2;
`;
export const ExpertiseOuterWrapper = styled.div`
  height: 130vh;
  background: #f2f2f2;
`;

export const DashBoardWrapper = styled.div`
  height: 100vh;
  background: #f2f2f2;
`;

export const WellComeBoxActaurial = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  .main-btn {
    display: flex;
    justify-content: center;
  }
  .lets-go {
    background: #393939;
    border-radius: 4px;
    min-width: 120px;
    height: 48px;
    color: #ffffff;
    margin-top: 35px;
  }
  .lets-go:hover,
  .lets-go:active,
  .lets-go:focus {
    border: none;
  }
  .ant-input,
  .ant-select {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 359px;
    min-height: 48px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
  .parentDiv {
    width: 30% !important;
  }
  .softwaresDiv {
    justify-content: space-between;
    display: flex;
    padding: 3px;
  }
  .grading-text {
    color: rgb(187, 187, 187);
    font-size: 13px;
  }
  .grading-text span {
    margin-left: 10px !important;
  }

  #areaOfExpertise,  #qualification{
    padding-top:15px !important;
  }
  
`;

export const MainHeading = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #393939;
  text-align: center;
`;

export const SecondaryHeading = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: #393939;
`;
export const InputsHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #393939;
`;
export const SubHeading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #393939;
  text-align: center;
  width: 484px;
  overflow-wrap: break-word;
`;

export const RadioButtonTitle = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */

  /* Placeholder text */

  color: #acacac;

  &.active {
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #000 !important;
  }
`;
