import styled from "styled-components";

export const FormPrimaryHeading = styled.div``;

export const ParentWrapper = styled.div`
  .form-primary-heading {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;

    color: #393939;
  }

  .form-text {
    font-family: "Karla";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    color: #393939;
  }
  .ant-input {
    box-shadow: none !important;
    align-item: center !important;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 38px !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 47px;
    padding: 9px 11px;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    height: 45px;
    padding-top: 6px;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #393939;
    border-color: #393939;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: white;
  }
  .ant-select-multiple .ant-select-selector {
    padding: 10px 3px;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-input:hover {
    border-color: rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px solid rgba(0, 0, 0, 0.25) !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: rgba(0, 0, 0, 0.25) !important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
    background-color: "none !important";
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector
    :active {
    border-color: rgba(0, 0, 0, 0.25) !important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
    background-color: "none !important";
  }
  .ant-picker .ant-picker-input,
  .ant-picker.ant-picker-range {
    display: flex;
    flex-direction: row-reverse;
  }
  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 10px;
    margin-right: 15px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    // background-color: #000 !important;
  }

  .ant-select-selection-search-option-active:not(
      .ant-select-item-option-disabled
    ) {
    background-color: #000;
  }
  .ant-radio-inner::after {
    background-color: #393939 !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #393939 !important;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: #393939 !important;
  }

  .ant-pagination-item-active a {
    color: #393939 !important;
  }
  .ant-pagination-item-active {
    // border-color: #393939 !important;
    border-color: #d9d9d9 !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #bfbfbf !important;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #bfbfbf !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #393939 !important;
    border-color: #393939 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #393939 !important;
  }

  .ant-select-multiple .ant-select-selector {
    display: block !important;
  }
  .ant-select-multiple .ant-select-selector::after {
    content: none !important;
  }

  // .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  //   padding-top: 15px;
  // }
`;
