import { Button, Col, Form, Input, Row } from "antd";
import BoxActaurialAuthIcon from "Assets/Logos/BoxActaurialAuthIcon";
import { AppRoutes } from "constants/routes";
import { useAppDispatch } from "Hooks/Store";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ResetPasswordAction } from "Redux/App/Actions/Auth";
import { FormWrapper, LoginCard, LoginWrapper } from "./styles";
const ResetPassword = () => {
  const { token } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    dispatch<any>(ResetPasswordAction(values, token as string, navigate));
  };
  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginBottom: "22px" }}>
              <BoxActaurialAuthIcon />
            </div>
          </Col>
        </Row>

        <h1 className="form-primary-heading">Reset Password</h1>
        <FormWrapper>
          <Form
            form={form}
            layout="vertical"
            className="FormWrapper"
            onFinish={onFinish}
          >
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
                {
                  min: 5,
                  message: "Password must be minimum 5 characters long",
                },
              ]}
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your password again",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The password does not match");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="form-submit-btn"
                  type="primary"
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </div>
            </Form.Item>
            <p>
              <span style={{ color: "#BBBBBB" }}>Remember password?&nbsp;</span>
              <Link
                to={AppRoutes.login}
                style={{ color: "#393939", textDecoration: "underline" }}
              >
                Login
              </Link>
            </p>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default ResetPassword;
