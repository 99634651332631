import { AppRoutes } from "constants/routes";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FooterWrapper } from "./styles";

const Footer = () => {
  return (
    <FooterWrapper>
      <footer className="footer">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <p className="text-white text-center text-md-start">
                Copyright © 2023 Box Actuarial - All Rights Reserved.
              </p>
            </Col>
            <Col md={6}>
              <div className="text-end text-white footer-link">
                <NavLink
                  to={AppRoutes.termsConditions}
                  className="text-decoration-none text-white ml-4"
                >
                  Terms & Conditions
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </FooterWrapper>
  );
};

export default Footer;
