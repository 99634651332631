import styled from "styled-components";

export const LayoutWrapper = styled.div`
  height: 100%;
  background: #ffffff;
  .ant-layout-content {
    background: #ffff;
  }
  .ant-layout-sider-light .ant-layout-sider-trigger {
    left: 0;
    border-right: 1px solid #f0f0f0;
  }
  .ant-layout {
    background: #ffff;
  }
  .ant-layout-header {
    line-height: 16px;
  }

  .col-adjust {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .header-bg-color {
    background-color: #ffffff;
    height: auto;
    padding-bottom: 30px;
  }
  .user-profile {
    text-align: right;
    padding-left: 12px;
  }
  .user-wrapper {
    dispaly: flex;
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 50px;
  .pagination {
    display: flex;
    align-items: center;
    gap: 40px;
    p {
      margin-bottom: 0px;
    }
    a {
      color: #393939;
      font-weight: bold;
    }
  }
`;
export const BoxLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;

  .logout-btn {
    background: white !important;
  }
`;
export const BorderLine = styled.div`
  width: 1px;
  height: 34px;
  margin: 18px 18px 18px 0px;
  background: #2d405a;
  opacity: 0.1;
`;
export const ProfileName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;
export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  color: #2d405a;
`;
export const Email = styled.div``;

export const StepsWrapper = styled.div`
  width: 65%;
  margin: auto;
  margin-top: 25px;

  .ant-steps-item-container {
  }
  .ant-steps {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
    border-radius: 67px;
    padding: 0px 10px;
    height: 45px;
    display: flex;
    align-items: center;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: none;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #000;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #000;
  }
  .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-item {
    border-right: 1px solid #acacac;
  }
  .ant-steps-item:last-child {
    border-right: 0;
    padding-right: 60px;
  }
`;
