import styled from "styled-components";

export const TermsWrapper = styled.div`
  h1 {
    font-size: 16px;
  }
  h2 {
    margin-top: 3rem;
  }
  .footer {
    background-color: #393939;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    margin-top: 6rem;
  }
  .footer-link {
    display: flex;
    gap: 10px;
    justify-content: end;
  }
  @media (max-width: 768px) {
    .footer-link {
      justify-content: center;
    }
  }
`;
