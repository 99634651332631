import styled from "styled-components";

export const ContactWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400;700&display=swap");
  h1,
  h2,
  h3,
  h5,
  p {
    font-family: "Old Standard TT", serif;
  }
  height: 100vh;
  background: rgb(242, 248, 255);
  input {
    height: 48px;
    border-radius: 4px;
  }
  .form-control {
    font-size: 14px;
  }
  .form-control:focus,
  .form-control:active {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
  }
  .btn-submit {
    background: rgb(57, 57, 57);
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 2px;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    color: rgb(255, 255, 255);
    border: none;
  }
`;
