import axios, { AxiosResponse } from "axios";
import {
  requestStart,
  requestCompleted,
  requestSuccess,
  userLoggedIn,
  userLoggedOut,
} from "../..";
import { notification } from "antd";
import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { GetItemsAction, GetLocationsAction } from "../Items";
import { AppRoutes } from "constants/routes";

export function SignupAction(
  data: { name: string; email: string; password: string; role: string },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/register`, data)
        .then((res: AxiosResponse) => {
          if (res) {
            navigate(AppRoutes.otp.replace(':userId?', res.data.id), { state: data.email });
            dispatch(requestCompleted());
            notification.success({
              message: "OTP sent successfully",
              description:
                "If you didn't get OTP in your email then please check your Spam folder for OTP",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: "Email already exists",
            description: "Please enter another email",
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}



export function OTPAction(
  data: { otp: string; userId: string | undefined },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/verify`, data)
        .then((res: AxiosResponse) => {
          if (res?.data) {
            navigate(AppRoutes.login);
            dispatch(requestCompleted());
            notification.success({
              message: "OTP verified successfully",
            });
          } else {
            dispatch(requestCompleted());
            notification.error({
              message: "OTP not verified",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResendOTPAction(data: { email: string }) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/resend`, data)
        .then((res: AxiosResponse) => {
          if (res?.data) {
            // navigate("/otp");
            dispatch(requestCompleted());
            notification.success({
              message: "OTP Sent Again successfully",
            });
          } else {
            dispatch(requestCompleted());
            notification.error({
              message: "OTP not sent",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function LoginAction(
  data: { email: string; password: string },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, data)
        .then((res: AxiosResponse) => {
          if (res?.data?.token?.user?.isVerified) {
            console.log("userData");
            console.log({
              ...res.data.token.user,
              ...res.data.userInformation,
            });
            localStorage.setItem("token", res.data.token.accessToken);
            dispatch<any>(GetLocationsAction());
            dispatch<any>(GetItemsAction(0, navigate));
            dispatch(
              userLoggedIn({
                userData: {
                  ...res.data.token.user,
                  ...res.data.userInformation,
                },
                isRecordEntered: res.data.isRecordExist,
              })
            );
            dispatch(requestCompleted());
            navigate(AppRoutes.portal);
            notification.success({
              message: "Login successfully",
            });
          } else {
            dispatch(requestCompleted());
            notification.error({
              message: "Please enter valid credentials",
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          dispatch(requestCompleted());
          if (error?.response?.status === 401) {
            notification.error({
              message: error?.response?.data?.message,
              description: error?.response?.data?.description,
            });
          } else {
            notification.error({
              message: "Username or password does not match",
            });
          }
          console.log(error?.response?.data?.error);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetTermsAndCondition(

) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/T&C`, {})
        .then((res: AxiosResponse) => {
          if (res) {
            console.log(res)
            //       localStorage.getItem("token");
            // dispatch<any>(GetLocationsAction());
            // dispatch<any>(GetItemsAction(0));
            dispatch(requestSuccess(res));


          }
        })
        .catch((error) => {
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function PostTermsAndCondition(
  obj: any
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/T&C/upload`, obj)
        .then((res: AxiosResponse) => {
          if (res) {
            notification.success({
              message: " Terms & Conditions Uploaded Successfully!"
            });
          }
        })
        .catch((error) => {
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function LogoutAction(navigate: NavigateFunction) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    localStorage.removeItem("token");
    navigate(AppRoutes.login);
    dispatch(requestCompleted());
    dispatch(userLoggedOut());
  };
}

export function ContactAction(data: {
  name: string;
  email: string;
  message: string;
}) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        // .post(`http://localhost:3000/auth/contact-us`, data)
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/contact-us`, data)
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            notification.success({
              message: "Email sent successfully",
              description: "We will contact you soon",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ForgetPasswordAction(
  data: { email: string },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/forget-password`,
          data
        )
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            navigate(AppRoutes.linkSuccess);
            notification.success({
              message: "Email sent successfully",
              description: "Check your email for reset password link",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResetPasswordAction(
  data: { password: string },
  token: string,
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            navigate(AppRoutes.updated);
            notification.success({
              message: "Password updated successfully",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
