import { useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { LoginCard, LoginWrapper, FormWrapper } from "./styles";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useAppDispatch } from "Hooks/Store";
import { OTPAction, ResendOTPAction } from "Redux/App/Actions/Auth";
import BoxActaurialAuthIcon from "Assets/Logos/BoxActaurialAuthIcon";
import { loading as stateLoading } from "Redux/App";
import { useSelector } from "react-redux";
import { AppRoutes } from "constants/routes";

const Otp = () => {
  const { state } = useLocation();
  const { userId }= useParams();
  const loading = useSelector(stateLoading);
  const [form] = Form.useForm();
  const [otpCode, setOtpCode] = useState("");
  const handleChange = (otp: any) => setOtpCode(otp);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = () => {
    dispatch<any>(OTPAction({ otp: otpCode, userId }, navigate));
  };

  const onResetClick = () => {
    dispatch<any>(ResendOTPAction({ email: state }));
  };
  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginBottom: "22px" }}>
              <BoxActaurialAuthIcon />
            </div>
          </Col>
        </Row>

        <h1 className="form-primary-heading">Verification code</h1>
        <p className="form-text ">This won’t take long</p>
        <FormWrapper>
          <Form
            form={form}
            layout="vertical"
            className="FormWrapper"
            onFinish={onFinish}
          >
            <OtpInput
              value={otpCode}
              onChange={handleChange}
              numInputs={4}
              separator={<span> </span>}
              shouldAutoFocus={true}
              isInputNum={true}
              containerStyle={"otp-form"}
              inputStyle={"otp-input"}
            />

            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="form-submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit and continue
                </Button>
              </div>
            </Form.Item>
            <p>
              <span style={{ color: "#BBBBBB" }}>
                Didn’t receive the code? &nbsp;
              </span>
              <Link
                to={AppRoutes.otp}
                onClick={onResetClick}
                style={{ color: "#393939" }}
              >
                Send again
              </Link>
              <br />
              <span style={{ color: "#BBBBBB" }}>
                Make sure to check your spam folder
              </span>
            </p>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default Otp;
