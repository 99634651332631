import styled from "styled-components";

export const WellComeBoxActaurial = styled.div`
  max-width: 50%;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 120px;
  .main-btn {
    display: flex;
    justify-content: center;
  }
  .lets-go {
    background: #393939;
    border-radius: 4px;
    width: 120px;
    height: 48px;
    color: #ffffff;
    margin-top: 35px;
  }
  .lets-go:hover,
  .lets-go:active,
  .lets-go:focus {
    border: none;
  }
  .ant-input,
  .ant-select {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 359px;
    min-height: 48px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-table {
    background: none;
  }
`;
export const DashBoardWrapper = styled.div`
  background: #f2f2f2;

  .back-btn {
    display: flex;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 16px;
    left: 72px;
    top: 160px;

    font-family: "Karla";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;

    color: #393939;
  }
  .back-btn:hover {
    background-color: transparent !important;
  }
`;
export const MainHeading = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #393939;
  text-align: center;
`;

export const UserHeading = styled.p`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 0px;
  margin-top: 5px;
  /* identical to box height, or 29px */

  /* Primary Black */

  color: #393939;
`;

export const UpdateMyProfileButton = styled.button`
  width: 172px;
  height: 48px;

  /* bg-color */

  background: #f2f2f2;
  border-radius: 4px;
`;

export const ContractorHeading = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  margin-top: 36px;
  margin-bottom: 16px;
  color: #393939;
`;

export const SettingHeading = styled.p`
  ont-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  margin-top: 0px;
  margin-bottom: 30px;
  color: #393939;
`;

export const SettingPrimaryHeading = styled.p`
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */

  /* Placeholder text */

  color: #acacac;
`;

export const HeadingText = styled.p`
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */

  /* Primary Black */

  color: #393939;
`;
