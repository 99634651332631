import { Avatar, Button, Col, Image, Layout, Row } from "antd";
import {
  LayoutWrapper,
  HeaderContent,
  BoxLogo,
  ProfileWrapper,
  BorderLine,
  ProfileName,
  Name,
  Email,
  StepsWrapper,
} from "./styles";
import Sidebar from "Components/Shared/Sidebar";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Form, Dropdown, Menu, Space, Typography, Steps } from "antd";
import React, { useEffect, useState } from "react";
import profile from "Assets/Images/profile.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import BoxActaurialIcon from "Assets/Logos/BoxActaurialIcon";
import { useAppDispatch, useAppSelector } from "Hooks/Store";
import { useSelector } from "react-redux";
import { setStepCount, stepCount, user } from "Redux/App";
import { LogoutAction, PostTermsAndCondition } from "Redux/App/Actions/Auth";
import { Link } from "react-router-dom";
import Footer from "Components/Shared/Footer";
import { Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
const { Content } = Layout;
const { Header } = Layout;
const { Dragger } = Upload;

interface LayoutPropsTypes {
  children: JSX.Element;
}

const CustomLayout: React.FC<LayoutPropsTypes> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const userData = useSelector(user);
  const count = useSelector(stepCount);
  const dispatch = useAppDispatch();
  const [file, setfile] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {

    setIsModalOpen(false);
    // values.file = file

    dispatch<any>(PostTermsAndCondition({ file: file },));

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      // if (status !== 'uploading') {
      const file: any = info.file.originFileObj;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const uint8Array = new Uint8Array(event.target.result);
        let base64String = '';
        for (let i = 0; i < uint8Array.length; i++) {
          base64String += String.fromCharCode(uint8Array[i]);
        }
        const mimeType = file.type;
        const filename = encodeURIComponent(file.name);
        const dataUri = `data:${mimeType};filename=${filename};base64,${base64String}`;
        setfile(dataUri);
        console.log(dataUri)
      };
      // Read the File as ArrayBuffer
      reader.readAsArrayBuffer(file);

      // }
      // if (status === 'done') {
      //   message.success(`${info.file.name} file saved for upload successfully.`);
      // } else if (status === 'error') {
      //   message.error(`${info.file.name} file saved for upload failed.`);
      // }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    console.log("pathname", pathname);
    if (pathname === "/personal-information") {
      dispatch<any>(setStepCount(0));
      console.log("/personal-information");
      console.log("count", count);
    }
    if (pathname === "/expertise") {
      dispatch<any>(setStepCount(1));
      console.log("/expertise");
      console.log("count", count);
    }
    if (pathname === "/soft-skill") {
      dispatch<any>(setStepCount(2));
      console.log("/soft-skill");
      console.log("count", count);
    }
  });
  const menu = (
    <Menu
      selectable
      // defaultSelectedKeys={["1"]}
      items={[
        {
          key: "1",
          label: (
            <Button
              className="logout-btn"
              type="text"
              onClick={() => {
                dispatch<any>(LogoutAction(navigate));
                setLoading(true);
              }}
            >
              Log Out
            </Button>
          ),
        },
      ]}
      style={{ position: "absolute", top: 12 }}
    />
  );
  // Page name on Header
  let homeText = (
    <>
      <Link to="/">Home</Link>
    </>
  );
  let headerText = homeText;

  const pageLocation = useLocation();

  if (pageLocation.pathname === "/portal") {
    headerText = (
      <>
        {homeText} {" > "} Dashboard
      </>
    );
  } else if (pageLocation.pathname === "/portal/profile-settings") {
    headerText = (
      <>
        {homeText} {" > "}
        <Link to="/portal">Dashboard</Link> {" > "} Profile Settings
      </>
    );
  } else if (pageLocation.pathname === "/portal/soft-skill") {
    headerText = (
      <>
        {homeText} {" > "}
        <Link to="/portal">Dashboard</Link> {" > "} Software skills
      </>
    );
  } else if (pageLocation.pathname === "/portal/expertise") {
    headerText = (
      <>
        {homeText} {" > "}
        <Link to="/portal">Dashboard</Link> {" > "} Expertise
      </>
    );
  } else if (pageLocation.pathname === "/portal/personal-information") {
    headerText = (
      <>
        {homeText} {" > "}
        <Link to="/portal">Dashboard</Link> {" > "} Personal Information
      </>
    );
  }

  return (
    <LayoutWrapper>
      <Layout className="layout-container">
        <Layout className="site-layout">
          <Header className="header-bg-color">
            <HeaderContent>
              <div className="pagination">
                <BoxLogo>
                  <BoxActaurialIcon width={115} height={26.76} />
                </BoxLogo>
                <p>{headerText}</p>
              </div>

              <ProfileWrapper>
                <Modal title="Terms & Conditions" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                      banned files.
                    </p>
                  </Dragger>
                  {/* <Form.Item
                    name="file"
                    rules={[
                      { required: true, message: "Please enter upload file" },

                    ]}
                  >
                    <Input className="pt-2" type="file" onChange={(e) => {
                      console.log(e.target.files)
                      const fileList: any = e.target.files
                      const file = fileList[0];
                      const reader = new FileReader();
                      reader.onload = (event: any) => {
                        const base64String = event.target.result.split(',')[1]; // Extract the Base64 data
                        console.log(base64String);
                        setfile(base64String) // This is the Base64 representation of the file
                      };
                      reader.readAsDataURL(file);
                    }} />
                  </Form.Item> */}
                </Modal>
                {userData?.email == "gerard@gmail.com" ? <button style={{ border: "1px solid black", borderRadius: "0px" }} className="btn btn-dark m-2 text-light " onClick={() => { showModal() }}>Add T&C</button> : null}

                <BorderLine />

                <ProfileName>
                  <Name>{userData?.displayName || userData?.name}</Name>
                  <Email>{userData?.email}</Email>
                </ProfileName>
                <div className="user-profile">
                  <Dropdown
                    trigger={["click"]}
                    overlay={menu}
                    placement="bottomRight"
                  >
                    <Typography.Link>
                      <Space>
                        {loading && <LoadingSpinner />}
                        <Avatar
                          size="large"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          icon={<UserOutlined style={{ color: "#393939" }} />}
                        />
                        {/* <Image src={profile} preview={false} /> */}
                        <DownOutlined style={{ color: "#393939" }} />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </div>
              </ProfileWrapper>
            </HeaderContent>
            {pathname === "/personal-information" ||
              pathname === "/soft-skill" ||
              pathname === "/expertise" ? (
              <StepsWrapper>
                <Steps
                  items={[
                    {
                      title: "Personal information",
                    },
                    {
                      title: "Expertise",
                    },
                    {
                      title: "Software skills",
                    },
                  ]}
                  current={count}
                // onChange={(current) => {
                //   console.log("onChange:", current);
                // }}
                />
              </StepsWrapper>
            ) : (
              <></>
            )}
          </Header>
          {/* <Header className="nav-fixed header-bg-color ">
            <HeaderContent>
              <div>
                <BoxActaurialIcon/>
              </div>
              <div >
                <div>|</div>
                <div className="user-profile">
                  <Dropdown
                    trigger={["click"]}
                    overlay={menu}
                    placement="bottomRight"
                  >
                    <Typography.Link>
                      <Space>
                        {loading && <LoadingSpinner />}
                        <Image src={profile} preview={false} />
                        <DownOutlined />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            </HeaderContent>
          </Header> */}
          <Content className="content-padding">{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </LayoutWrapper>
  );
};

export default CustomLayout;
