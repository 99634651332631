import axios from "axios";
import {
  requestStart,
  requestCompleted,
  requestSuccess,
  setStepCount,
  setLocations,
  setIsRecordEntered,
  setPersonalInformation,
  setExpertise,
  setSoftwareSkills,
  resetRequestStart,
  resetRequestCompleted,
} from "../..";
import { notification } from "antd";
import { Dispatch } from "redux";
import { Dispatch as ReactDispatch, SetStateAction } from "react";
import { FormInstance } from "antd";
import { NavigateFunction } from "react-router-dom";
import { AppRoutes } from "constants/routes";

interface ItemType {
  name: string;
  price: number;
  _id?: string;
}

//GET all Itemss
export function GetItemsAction(skip?: any, navigate?: any
) {
  return async (dispatch: Dispatch) => {

    dispatch(requestStart());
    let token = localStorage.getItem("token");
    console.log(skip, "skip");
    // const column = skip?.pageSize
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/personalInformation/?column=${skip?.pageSize ? skip?.pageSize : 10
          }&skip=${skip.current ? (skip.current - 1) * 10 : 0}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            dispatch(requestSuccess(res.data[0]));
          }
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });

          if (error.response.data.message == "Unauthorized") {
            localStorage.removeItem("token");
            navigate(AppRoutes.login);
          }
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

//GET all location
export function GetLocationsAction() {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    let token = localStorage.getItem("token");
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/personalInformation/locations`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            console.log(res.data);
            dispatch(setLocations(res.data));
          }
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

//Search Items
export function SearchItemsAction(values: any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    let token = localStorage.getItem("token");
    try {
      axios
        // .get(`${process.env.REACT_APP_API_BASE_URL}/personalInformation/?column=_id&name=${name}&location=${location}`, {
        //   headers: {
        //     "Authorization": `Bearer ${token}`,
        //   },
        // })
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/personalInformation/search?column=_id`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            dispatch(requestSuccess(res.data[0]));
          }
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResetItemsAction(values: any) {
  return async (dispatch: Dispatch) => {
    dispatch(resetRequestStart());
    let token = localStorage.getItem("token");
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/personalInformation/search?column=_id`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            dispatch(requestSuccess(res.data[0]));
          }
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
          dispatch(resetRequestCompleted());
        });
    } catch (error) {
      dispatch(resetRequestCompleted());
    }
  };
}

//Delete an Item
// export function DeleteItem(id: string) {
//   return async (dispatch: Dispatch, state: any) => {
//     dispatch(requestStart());
//     let token = localStorage.getItem("token");
//     try {
//       axios
//         .delete(`${process.env.REACT_APP_API_BASE_URL}/api/v1/item/${id}`, {
//           headers: {
//             "x-auth-token": token,
//           },
//         })
//         .then((res) => {
//           if (res) {
//             let stateData = state();

//             let records = stateData.app.data;

//             let filteredRecords = records.filter(
//               (record: ItemType) => record._id !== id
//             );
//             dispatch(requestSuccess(filteredRecords));
//           }
//         })
//         .catch((error) => {
//           notification.error({
//             message: error.response.data.message,
//           });
//           dispatch(requestCompleted());
//         });
//     } catch (error) {
//       dispatch(requestCompleted());
//     }
//   };
// }

//ADD an Item
// export function AddItem(
//   values: ItemType,
//   isModalOpen: { open: boolean; screen: string },
//   setIsModalOpen: ReactDispatch<
//     SetStateAction<{ open: boolean; screen: string }>
//   >,
//   form: FormInstance
// ) {
//   return async (dispatch: Dispatch, state: any) => {
//     dispatch(requestStart());
//     let token = localStorage.getItem("token");
//     try {
//       axios
//         .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/item/`, values, {
//           headers: {
//             "x-auth-token": token,
//           },
//         })
//         .then((res) => {
//           if (res) {
//             let stateData = state();
//             let newStateData = [res.data.data, ...stateData.app.data];
//             dispatch(requestSuccess(newStateData));
//             setIsModalOpen({ ...isModalOpen, open: false });
//             form.resetFields();
//           }
//         })
//         .catch((error) => {
//           notification.error({
//             message: error.response.data.message,
//           });
//           dispatch(requestCompleted());
//         });
//     } catch (error) {
//       dispatch(requestCompleted());
//     }
//   };
// }

//ADD an Item
// export function EditItem(
//   values: ItemType,
//   isModalOpen: { open: boolean; screen: string },
//   setIsModalOpen: ReactDispatch<
//     SetStateAction<{ open: boolean; screen: string }>
//   >,
//   form: FormInstance,
//   id: string
// ) {
//   return async (dispatch: Dispatch, state: any) => {
//     dispatch(requestStart());
//     let token = localStorage.getItem("token");
//     try {
//       axios
//         .put(
//           `${process.env.REACT_APP_API_BASE_URL}/api/v1/item/${id}`,
//           values,
//           {
//             headers: {
//               "x-auth-token": token,
//             },
//           }
//         )
//         .then((res) => {
//           if (res) {
//             let stateData = state();

//             let records = stateData.app.data;

//             let filteredRecords = records.filter(
//               (record: ItemType) => record._id !== id
//             );
//             console.log(res);
//             dispatch(requestSuccess([...filteredRecords, res.data.data]));
//             setIsModalOpen({ ...isModalOpen, open: false });
//             form.resetFields();
//           }
//         })
//         .catch((error) => {
//           notification.error({
//             message: error.response.data.message,
//           });
//           dispatch(requestCompleted());
//         });
//     } catch (error) {
//       dispatch(requestCompleted());
//     }
//   };
// }

//SAVE Personal Information
export function PersonalInformationAction(
  values: ItemType,
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch, state: any) => {
    dispatch(requestStart());
    let token = localStorage.getItem("token");
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/personalInformation/personal-information`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            navigate(AppRoutes.expertise);
            dispatch(setPersonalInformation({ ...values }));
            dispatch(requestCompleted());
            notification.success({
              message: "Personal information saved successfully",
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

//SAVE Expertise
export function ExpertiseAction(values: any, navigate: NavigateFunction) {
  return async (dispatch: Dispatch, state: any) => {
    dispatch(requestStart());
    let token = localStorage.getItem("token");
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/personalInformation/expertise`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            navigate(AppRoutes.softSkill);
            dispatch(setExpertise({ ...values }));
            dispatch(requestCompleted());
            notification.success({
              message: "Expertise saved successfully",
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

//SAVE Soft Skill
export function SoftSkillsAction(values: any, navigate: NavigateFunction) {
  return async (dispatch: Dispatch, state: any) => {
    dispatch(requestStart());
    let token = localStorage.getItem("token");
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/personalInformation/sofware-skills`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            console.log("res.data.isRecordEntered");
            console.log(res.data.isRecordExist);
            dispatch(
              setSoftwareSkills({
                ...values,
                isRecordEntered: res.data.isRecordExist,
              })
            );
            dispatch<any>(GetLocationsAction());
            dispatch<any>(GetItemsAction(0));
            // setTimeout(() => {
            // dispatch(setIsRecordEntered(values['userID']));
            navigate(AppRoutes.portal);
            dispatch(requestCompleted());
            notification.success({
              message: "Sotfware skills saved successfully",
            });
            // }, 5000);
          }
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
